import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import greenArrow from '../images/headerGreen.svg'
import whiteArrow from '../images/headerWhite.svg'

const styles = {
	header: {
		boxSizing: "border-box",
		position: "relative"	
	},
	bg_img: {
		height: "100%",
		left: "0px",
		objectFit: "cover",
		position: "absolute",
		top: "0px",
		width: "100%"
	},
	logo: {
		boxSizing: "border-box",
		height: "75px",
		objectFit: "contain",
		padding: "1em",
	},
	nav:{
		position: 'absolute',
		top: '0px',
		right: '10px',
		display: "flex",
		padding: "1em 0"
	},
	navOption:{
		marginRight: '0px',
		color: "#fff",
		position: "relative"
	},
	posAbs:{
		position: 'absolute',
		top: '15%',
		left: '5px',
		right: '0',
		textAlign: 'center'
	},
	selected:{
		fontSize: '12px',
		textTransform: 'capitalize',
		color: "#fff"
	},
	normal:{
		fontSize: '12px',
		textTransform: 'capitalize'
	},
	navImg: {
		maxWidth: '120px'
	}

}

const ids = [
	{id: 'intro',name: "Introduction" ,link: '/home'}, 
	{id: 'benchmarking',name: "Benchmarking" ,link: '/Benchmarking'}, 
	{id: 'prioritization',name: "Prioritization" ,link: '/Prioritization'}, 
	{id: 'works',name: "How it Works" ,link: '/FacilityType'}, 
	{id: 'implementation',name: "Implementation" ,link: '/Implementation'}, 
	{id: 'advisory',name: "Custom Advisory" ,link: '/CustomAdvisory'}, 
	{id: 'reach',name: "Reach Us" ,link:'/ContactForm'}
];
const Header = ({uniqueName}) => {
	const isBrowser = typeof window !== "undefined"
	let pathFlag;
	if(isBrowser){
		let path = window.location.pathname;
		if(path === "/"){
			pathFlag = false;
		}
		else{
			pathFlag = true;
		}
	}
	else{
		pathFlag = true;
	}

	function selectedClass(id){
		if(uniqueName === id){
			return true;
		}
		else{
 			return false;
		}
	}
	function selectImage(id){
		if(uniqueName === id){
			return greenArrow;
		}
		else{
 			return whiteArrow;
		}
	}
	return ( 
		<header style={ styles.header }>

			<StaticImage
		        alt=""
		        src="../images/header.png"
		        style={ styles.bg_img }
		      />

			<StaticImage
		        alt=""
		        src="../images/logo-w.svg"
		        style={ styles.logo }
		        imgStyle={ styles.logo }
		      />
			{pathFlag && <div className ="navigation" style={styles.nav}>
				{ids.map((ids) => (
					<a href={ids.link} className="navOption" style={styles.navOption}>
						<img src={selectImage(ids.id)} style={styles.navImg}/>
						<div style={styles.posAbs}>
							<p key={ids.id} style={selectedClass(ids.id) ? styles.selected : styles.normal}> {ids.name}</p>
						</div>							
					</a>
					))
				}
			</div>}
		</header>
	)
}

export default Header