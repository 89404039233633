import * as React from 'react'
import Header from '../components/header'
import '../styles/style.scss'
import Footer from './footer'



const Layout = ({ pageTitle, nextBottomSlideBtn, nextBottomSlideBtnLink, id, children}) => {

  if (typeof window !== 'undefined') {
    window.onload = function(){
      document.body.setAttribute("oncontextmenu", 'return false;');
    }
  }
  
  if (id === 'whatIsSouthern'){
    return (
      <div className='page'>
        <Header uniqueName="intro" />
        <title>{pageTitle}</title>
        <main className='wrapper' id={id}>
          <h1>{pageTitle}</h1>
          {children}
        </main>
        <Footer nextBottomSlideBtn={nextBottomSlideBtn} nextBottomSlideBtnLink={nextBottomSlideBtnLink} id="partHome"/>
      </div>
    );  
  }
  else if(id === 'home'){
    return (
      <div className='page'>
        <Header uniqueName="home"/>
        <title>{pageTitle}</title>
        <main className='wrapper'>
          <h1>{pageTitle}</h1>
          {children}
        </main>
        <Footer nextBottomSlideBtn={nextBottomSlideBtn} nextBottomSlideBtnLink={nextBottomSlideBtnLink} id="home"/>
      </div>
    );   
  }
  else{
    return (
      <div className='page'>
        <Header uniqueName={id}/>
        <title>{pageTitle}</title>
        <main className='wrapper'>
          <h1>{pageTitle}</h1>
          {children}
        </main>
        <Footer nextBottomSlideBtn={nextBottomSlideBtn} nextBottomSlideBtnLink={nextBottomSlideBtnLink} id="partHome"/>
      </div>
    );
  }
}
export default Layout