import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import '../styles/style.scss';
import { navigate } from 'gatsby';

const Footer = ( {nextBottomSlideBtn, nextBottomSlideBtnLink, id} ) => {
  if(id === 'partHome'){
    return (
        <footer>
          <div className="footer-left">
          </div>
          <div className="footer-middle">
            {nextBottomSlideBtn && 
              <Link 
                className="next-bottom-slide-btn" 
                to={nextBottomSlideBtnLink} 
                activeStyle={{backgroundColor:'transparent'}}
              > 
                <StaticImage
                  alt="down-arrow"
                  src="../images/arrow-down.png"
                />
                <span>{nextBottomSlideBtn}</span>
              </Link>
            }
          </div>
          <div className="footer-right">
            <div className="navBtns">
              <button className="backBtn" onClick={() => navigate(-1)}>
                <StaticImage
                  alt="back"
                  src="../images/back-btn.svg"
                  style={{ cursor: "pointer" }}
                />
              </button>

              <button className="homeBtn" onClick={()=>{navigate("/home")}}>
                <StaticImage
                  alt="home"
                  src="../images/home-btn.svg"
                  style={{ cursor: "pointer" }}
                />
              </button>
            </div>
          </div>
        </footer>
    )
  }else{
    return (
        <footer>
          <div className="footer-left">
          </div>
          <div className="footer-middle">
            {nextBottomSlideBtn && 
              <Link 
                className="next-bottom-slide-btn" 
                to={nextBottomSlideBtnLink} 
                activeStyle={{backgroundColor:'transparent'}}
              > 
                <StaticImage
                  alt="down-arrow"
                  src="../images/arrow-down.png"
                />
                <span>{nextBottomSlideBtn}</span>
              </Link>
            }
          </div>
          <div className="footer-right">
            <div className="navBtns">
              <button className="backBtn" onClick={() => navigate(-1)}>
                <StaticImage
                  alt="back"
                  src="../images/back-btn.svg"
                  style={{ cursor: "pointer" }}
                />
              </button>

              <button className="homeBtn" onClick={()=>{navigate("/")}}>
                <StaticImage
                  alt="home"
                  src="../images/home-btn.svg"
                  style={{ cursor: "pointer" }}
                />
              </button>
            </div>
          </div>
        </footer>
    )
  }
}
export default Footer